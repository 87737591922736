/* eslint-disable @typescript-eslint/no-explicit-any */
import { RGBAColor } from '@deck.gl/core';
import { SolidPolygonLayer } from '@deck.gl/layers';
import { GeoJSON } from '../../../services/api';
import { useAppSelector } from '../../../state/store';
import {
  getActiveMarker,
  getCurrentLocation,
  getCurrentLocationChildLocations,
  getLocSensorStatusData,
} from '../../../state/selectors';
import { BandParamsType, dataColours, varNameBandParams } from '../../../utils/dataBandParams';
import { BandValues } from '../../SensorArrayWidgets/base';
import { hexToRgb } from '../mapHelpers';
import { themeProps } from '../../../styles/theme';

const defaultElevation = 7;
const defaultOccBand: BandParamsType[] = [
  {
    upperBound: 5,
    color: '#7fadd4',
    text: 'Low count',
    label: 'Low count',
  },
  {
    upperBound: 15,
    color: '#4c8cc2',
    text: 'Light count',
    label: 'Light count',
  },
  {
    upperBound: Infinity,
    color: dataColours.blue,
    text: 'Heavy count',
    label: 'Heavy count',
  },
];

//  Helper function to process a single feature with elevated data
function processFeature(feature: any, elevationPerLevel: number): any {
  // Only process features of type "area.floor"
  if (feature?.properties?.type === 'area.floor' && feature?.geometry?.type === 'MultiPolygon') {
    const elevation = feature.properties.level * elevationPerLevel;
    // Create a new feature object to avoid modifying the parameter
    return {
      ...feature,
      geometry: {
        ...feature.geometry,
        coordinates: feature.geometry.coordinates.map((polygon: any[]) =>
          polygon.map((ring: any[]) =>
            ring.map((point: number[]) => {
              // If the point has only x,y coordinates
              if (point.length === 2) {
                // Add the z-coordinate (elevation)
                return [...point, elevation];
              }
              // If it already has a z-coordinate, update it
              const newPoint = [...point];
              newPoint[2] = elevation;
              return newPoint;
            })
          )
        ),
      },
    };
  }
  // Return the feature unchanged if it's not a floor area
  return feature;
}

// Adds elevation to GeoJSON features of type "area.floor" by adding a z-coordinate to each point in the geometry.
function addElevationToFloors(geojson: GeoJSON, elevationPerLevel: number): GeoJSON {
  // Create a deep copy to avoid modifying the original data
  const result = JSON.parse(JSON.stringify(geojson));
  // Process a feature collection
  if (result.type === 'FeatureCollection' && Array.isArray(result.features)) {
    result.features = result.features.map((feature: any) =>
      processFeature(feature, elevationPerLevel)
    );
  }
  // Process a single feature
  else if (result.type === 'Feature') {
    return processFeature(result, elevationPerLevel);
  }
  return result;
}

function calculateWeightedColor(colorArray: BandValues[]) {
  let totalWeight = 0;
  let totalR = 0;
  let totalG = 0;
  let totalB = 0;

  colorArray.forEach((item) => {
    if (item.pct === 0) return;

    // Convert hex to RGB
    const rgb = hexToRgb(item.colour);
    if (!rgb) return;
    totalR += rgb[0] * item.pct;
    totalG += rgb[1] * item.pct;
    totalB += rgb[2] * item.pct;

    // Add to total weight
    totalWeight += item.pct;
  });

  // Normalize by total weight
  if (totalWeight > 0) {
    const r = Math.round(totalR / totalWeight);
    const g = Math.round(totalG / totalWeight);
    const b = Math.round(totalB / totalWeight);
    return [r, g, b, 255];
  }
  return hexToRgb(themeProps.colors.mediumGrey, 255); // Default if no valid colors
}

const useSolidPolygonLayer = (floorPlan: GeoJSON | undefined) => {
  const currentLocation = useAppSelector(getCurrentLocation);
  const childLocations = useAppSelector(getCurrentLocationChildLocations);
  const activeMarker = useAppSelector(getActiveMarker);
  const locSensorStatusData = useAppSelector(getLocSensorStatusData);

  const hasFloorPlan = floorPlan?.features && floorPlan?.features.length > 0;
  const varNameBand = varNameBandParams[activeMarker] ?? defaultOccBand;

  if (!hasFloorPlan || childLocations.length === 0) return null;

  const perBandSums = (locId: string) => {
    const result = varNameBand?.map(({ color }) => ({
      total: 0,
      pct: 0,
      colour: color,
    })) as BandValues[];

    const onlineVarData = locSensorStatusData.onlineData.get(activeMarker) ?? [];
    const locVarData = onlineVarData.filter((item) => locId && item?.location?.startsWith(locId));

    locVarData.forEach(({ value }) => {
      for (let i = 0; i < result.length; i++) {
        if ((value ?? Infinity) <= varNameBand[i].upperBound) {
          result[i].total += 1;
          break;
        }
      }
    });

    result.forEach((item, index) => {
      result[index].pct = (100 * item.total) / locVarData.length;
    });

    return result;
  };

  const getFillColor = (item: any) => {
    const locId = `${currentLocation}#${item.properties.name}`;
    const fillColor = calculateWeightedColor(perBandSums(locId));
    return fillColor as RGBAColor;
  };

  const elevatedData = addElevationToFloors(floorPlan, defaultElevation);

  return new SolidPolygonLayer({
    id: 'building',
    data: elevatedData.features,
    extruded: true,
    wireframe: true,
    getPolygon: (d: any) => d.geometry.coordinates[0],
    getElevation: 5,
    getFillColor: (d: any) => getFillColor(d),
    getLineColor: [0, 0, 0, 255], // black
    pickable: true,
  });
};

export default useSolidPolygonLayer;
